/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#myModal').on('shown.bs.modal', function() {
          $('#myModal .modal-header p').html($('h1 span').html());
        })
        $('#myModal2').on('shown.bs.modal', function() {
          
          var address_value = $('.whats-my-home-worth input').val();
          $('#input_2_4').val(address_value);

        })
        //Fade In First Featured 
        $('.row-listing').first().fadeIn();

        $('.view-more').on('click', function(e) {

          jQuery('.row-listing').show();
          jQuery(this).hide();
          e.preventDefault();

        });

        //Clear btn
        $('.tab-pane .clear-btn').on('click', function(){
          console.log('clear dat');
          $('input').val('');
          $('select').val('').trigger('chosen:updated');
        });


        $('.property-photos').slick({
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.property-photos-thumbnails',
        });
        $('.property-photos-thumbnails').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.property-photos',
            dots: false,
            focusOnSelect: true
        });
        $('#nav-icon3').click(function(){
          var API = $("#my-menu").data( "mmenu" );
          $(this).toggleClass('open');
          if ($(this).hasClass('open')) {
            API.open();
          }
          else {
            API.close();
          }
        });
        $("#my-menu").mmenu({
           offCanvas: {
              position: "right"
           }
          });
        $('#my-menu').data('mmenu').bind('closed', function () {
            $('#nav-icon3').removeClass('open');
        });

        $(".chosen-select").chosen({
          disable_search : true
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
            var contact_map;
            var contact_myLatLng = {lat: parseFloat(43.227602), lng: parseFloat(-79.881655)};
            contact_map = new google.maps.Map(document.getElementById('contact-map-holder'), {
              center: contact_myLatLng,
              scrollwheel: false,
              zoom: 15
            });
            var contact_marker = new google.maps.Marker({
              position: contact_myLatLng,
              map: contact_map
            });
      }
    },
    'property_detail': {
      init: function() {
        console.log('property detail');
        $('.description').readmore({
          maxHeight : 100,
          speed: 75
        }); 
      
        $("a[href='#map']").on('shown.bs.tab', function(){
            var map;
            var myLatLng = {lat: parseFloat(map_lat), lng: parseFloat(map_long)};
            map = new google.maps.Map(document.getElementById('map-holder'), {
              center: myLatLng,
              scrollwheel: false,
              zoom: 15
            });
            var marker = new google.maps.Marker({
              position: myLatLng,
              map: map
            });
        });
        $("a[href='#streetview']").on('shown.bs.tab', function(){
            var panorama;
            panorama = new google.maps.StreetViewPanorama(
            document.getElementById('street-view'),
            {
              position: {lat: parseFloat(map_lat), lng: parseFloat(map_long)},
              scrollwheel: false,
              pov: {heading: 165, pitch: 0},
              zoom: 1
            });
        });
        $("a[href='#birdseye']").on('shown.bs.tab', function(){
              var map;
              var myLatLng = {lat: parseFloat(map_lat), lng: parseFloat(map_long)};
              map = new google.maps.Map(document.getElementById('birds-eye'), {
                center: myLatLng,
                scrollwheel: false,
                zoom: 21,
                mapTypeId: google.maps.MapTypeId.SATELLITE
              });
              var marker = new google.maps.Marker({
                position: myLatLng,
                map: map
              });
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
